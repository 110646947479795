<template>
  <b-tabs fill justified>
    <b-tab :title="$t('system.netdb')">
      <!-- eslint-disable vue/no-v-text-v-html-on-component !-->
      <b-alert class="m-5" show variant="info" v-html="$t('views.swagger.netdb_auth_explain')"></b-alert>
      <div id="swagger-ui-netdb">
      </div>
    </b-tab>
    <b-tab :title="$t('system.netdb_oldrelease')">
      <!-- eslint-disable vue/no-v-text-v-html-on-component !-->
      <b-alert class="m-5" show variant="info" v-html="$t('views.swagger.netdb_auth_explain')"></b-alert>
      <div id="swagger-ui-netdb-oldrelease">
      </div>
    </b-tab>
    <b-tab :title="$t('system.netdb_deprecated')">
      <!-- eslint-disable vue/no-v-text-v-html-on-component !-->
      <b-alert class="m-5" show variant="info" v-html="$t('views.swagger.netdb_auth_explain')"></b-alert>
      <div id="swagger-ui-netdb-deprecated">
      </div>
    </b-tab>
    <b-tab :title="$t('system.middleware')">
      <div id="swagger-ui-middleware">
      </div>
    </b-tab>
  </b-tabs>
</template>

<script>
import SwaggerUI from 'swagger-ui'
import 'swagger-ui/dist/swagger-ui.css'

export default {
  name: 'SwaggerUI',
  mounted() {
    var self = this
    SwaggerUI({
      tagsSorter: 'alpha',
      apisSorter: 'alpha',
      url: '/api_4_1.yml',
      docExpansion: 'none',
      dom_id: '#swagger-ui-netdb',
      deepLinking: false,
      filter: true,
      presets: [
        SwaggerUI.presets.apis
      ],
      plugins: [
        SwaggerUI.plugins.DownloadUrl
      ],
      requestInterceptor: function (req) {
        if (self.$store.state.user) {
          req.headers.Authorization = 'Bearer ' + self.$store.state.token.token
          window.console.log('Authorized from Session')
        }
        return req
      }
    })
    SwaggerUI({
      tagsSorter: 'alpha',
      apisSorter: 'alpha',
      url: '/api_4_0.yml',
      docExpansion: 'none',
      dom_id: '#swagger-ui-netdb-oldrelease',
      deepLinking: false,
      filter: true,
      presets: [
        SwaggerUI.presets.apis
      ],
      plugins: [
        SwaggerUI.plugins.DownloadUrl
      ],
      requestInterceptor: function (req) {
        if (self.$store.state.user) {
          req.headers.Authorization = 'Bearer ' + self.$store.state.token.token
          window.console.log('Authorized from Session')
        }
        return req
      }
    })
    SwaggerUI({
      tagsSorter: 'alpha',
      apisSorter: 'alpha',
      url: '/api_3_2.yml',
      docExpansion: 'none',
      dom_id: '#swagger-ui-netdb-deprecated',
      deepLinking: false,
      filter: true,
      presets: [
        SwaggerUI.presets.apis
      ],
      plugins: [
        SwaggerUI.plugins.DownloadUrl
      ],
      requestInterceptor: function (req) {
        if (self.$store.state.user) {
          req.headers.Authorization = 'Bearer ' + self.$store.state.token.token
          window.console.log('Authorized from Session')
        }
        return req
      }
    })
    SwaggerUI({
      tagsSorter: 'alpha',
      apisSorter: 'alpha',
      url: '/api/openapi.json',
      docExpansion: 'none',
      dom_id: '#swagger-ui-middleware',
      deepLinking: false,
      filter: true,
      presets: [
        SwaggerUI.presets.apis
      ],
      plugins: [
        SwaggerUI.plugins.DownloadUrl
      ],
      requestInterceptor: function (req) {
        if (self.$store.state.token) {
          req.headers.Authorization = 'Bearer ' + self.$store.state.token.token
          window.console.log('Authorized from Session')
        }
        return req
      }
    })
  }
}
</script>

<style scoped>

</style>
